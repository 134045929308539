<template>
  <v-container class="pa-0" fluid>
    <page-title :heading="$t('customers.lang_customerDepartments') "
                :subheading="$t('customers.lang_customerDepartmentsAdd')"
                :icon="icon"
                show-previous-button
    ></page-title>
    <div class="app-main__inner">
      <CreateDepartment/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle";
import CreateDepartment from "../../../../components/customers/customersettings/department/CreateDepartment";

export default {
  name: "CreateDepartmentView",
  components: {CreateDepartment, PageTitle},
  data: () => ({
    icon: 'pe-7s-tools icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>
